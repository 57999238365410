<template>
  <ul
    class="pl-2 py-2 rounded-lg border border-dashed border-gray-400 max-h-[172px] overflow-y-auto overflow-x-hidden"
  >
    <li
      v-for="document in listOfDocuments"
      :key="document.documentId"
      class="flex justify-between items-center"
    >
      <span class="text-truncate max-w-[30rem]">
        {{ document.documentName }}
      </span>
      <v-btn
        v-show="batchState !== 'ongoing'"
        :disabled="listOfDocuments && listOfDocuments.length <= 2"
        icon
        size="small"
        variant="text"
        :class="{
          '!opacity-0': listOfDocuments && listOfDocuments.length <= 2
        }"
        @click.stop="emit('remove-item', document.documentId)"
      >
        <v-icon color="philippineSilver" size="16"> fal fa-close </v-icon>
      </v-btn>
      <div
        v-if="batchState === 'ongoing'"
        class="h-10 w-10 flex items-center justify-center"
      >
        <VIcon color="blue" class="animate-spin">
          fa-regular fa-spinner-third
        </VIcon>
      </div>
    </li>
  </ul>
</template>

<script setup lang="ts">
import { type PropType } from 'vue';
import type { Document } from '@/types/Document';
import { computed } from 'vue';

const props = defineProps({
  documents: {
    type: Array as PropType<Document[]>,
    required: true
  },
  batchState: {
    type: String as PropType<
      | 'error'
      | 'idle'
      | 'required-authority-service'
      | 'unmatching-authority-service'
      | 'uploaded-files-max-size'
      | 'checking-auth-service'
      | 'unavailable-mobile-id'
      | 'ongoing'
      | 'completed'
    >,
    required: true
  }
});

const listOfDocuments = computed(() => props.documents);
const emit = defineEmits(['remove-item']);
</script>
