<template>
  <v-list>
    <v-list-subheader>
      {{ i18n.t('settings.menu_headers.organization') }}
    </v-list-subheader>
    <div v-if="filteredOrganizations.length === 1" class="px-3">
      <div class="pl-3 border-[1px] border-[#9E9E9E] rounded-md">
        {{ filteredOrganizations[0].title }}
        <organization-item :item="filteredOrganizations[0]" />
      </div>
    </div>
    <div v-else class="px-3">
      <v-select
        v-bind="$attrs"
        :model-value="internalValue"
        item-title="display_name"
        :items="filteredOrganizations"
        return-object
        single-line
        hide-details
        :label="i18n.t('settings.menu_headers.organization')"
        :placeholder="i18n.t('texts.select_organization')"
        @update:model-value="(val) => (internalValue = val)"
      >
        <template #item="{ props, item }">
          <v-list-item-subtitle
            v-if="item.raw.type === 'subheader'"
            class="m-3"
          >
            {{ item.raw.title }}
          </v-list-item-subtitle>
          <v-list-item v-else v-bind="props" title="">
            <organization-item
              :max-width="240"
              :active="item.raw.group_id === internalValue.group_id"
              :item="item.raw"
            />
          </v-list-item>
        </template>
        <template #selection="{ item }">
          <organization-item :max-width="150" :item="item.raw" />
        </template>
      </v-select>
    </div>
  </v-list>
</template>

<script setup lang="ts">
import { useDeepAdminStore } from '@/stores/deepadmin/deepadmin';
import type { Company, OrganizationHeader } from '@/types/deepadmin/Company';
import { VerificationState } from '@/types/enums/VerificationState';
import { ref, type PropType, watch, computed, watchEffect } from 'vue';
import { useI18n } from 'vue-i18n';

const emits = defineEmits(['update:model-value']);
const props = defineProps({
  modelValue: {
    type: Object as PropType<Company>,
    default: undefined
  }
});

const i18n = useI18n();
const internalValue = ref();
const deepAdminStore = useDeepAdminStore();

watch(internalValue, (newValue, oldValue) => {
  if (newValue && oldValue && newValue.group_id !== oldValue.group_id) {
    emits('update:model-value', newValue);
  }
});

watchEffect(() => {
  internalValue.value = props.modelValue;
});

const filteredOrganizations = computed<(Company | OrganizationHeader)[]>(() => {
  if (deepAdminStore.settingsSelectableOrganizations) {
    const orgs = deepAdminStore.settingsSelectableOrganizations.filter(
      (organization) => {
        return (
          organization.verification_state !== VerificationState.NONE &&
          organization.structure !== 'personal'
        );
      }
    );

    const personalSpaces =
      deepAdminStore.settingsSelectableOrganizations.filter((organization) => {
        return (
          organization.verification_state !== VerificationState.NONE &&
          organization.structure === 'personal'
        );
      });

    let availableOrgs: (Company | OrganizationHeader)[] = [];

    if (orgs.length > 0) {
      availableOrgs.push({
        title: String(i18n.t('labels.organizations')),
        type: 'subheader'
      });
      availableOrgs = [...availableOrgs, ...orgs];
    }

    if (personalSpaces.length > 0) {
      availableOrgs = [
        ...availableOrgs,
        {
          type: 'subheader',
          title: String(i18n.t('labels.personal'))
        },
        ...personalSpaces
      ];
    }

    return availableOrgs;
  } else {
    return [];
  }
});
</script>
