<template>
  <v-container v-if="preferencesStore.preferences" class="pt-0">
    <div
      class="sticky top-0 z-10 bg-white pt-8 pb-4"
      :class="$vuetify.display.mdAndUp ? 'pb-4' : 'pb-2'"
      data-test-id="document-list-scrollable"
    >
      <div :class="$vuetify.display.mdAndUp ? 'h-[90px]' : 'h-[80px]'">
        <ViewSwitch class="min-w-0 px-2" :disabled="query.isFetching" />

        <ActionBar
          v-if="selection.length > 0"
          :total="selection.length"
          :selected-documents="selectedDocuments"
          @clear-selection="selection = []"
        />
        <DocumentsTotal
          v-if="selection.length === 0"
          class="px-2 mt-5"
          :total="query?.data?.total || 0"
        />
      </div>
    </div>

    <div class="px-2 mt-6" data-test-id="document-list-container">
      <v-progress-linear
        v-if="query.isPending"
        color="primary"
        indeterminate
        rounded
      ></v-progress-linear>

      <span v-if="query.isError">Error: {{ query.error.message }}</span>
      <template v-if="query.data">
        <v-progress-linear
          v-if="query.isFetching && !query.isFetchingNextPage"
          color="primary"
          indeterminate
          rounded
        ></v-progress-linear>
        <template v-else>
          <!-- Table header -->
          <template v-if="query.data.items.length > 0">
            <div
              v-if="viewOption === 'list' && !newList"
              class="w-full h-12 pr-16 flex justify-between items-center border-b border-gray-200"
            >
              <div class="flex items-center justify-center">
                <v-checkbox-btn
                  ref="selectAllCheckbox"
                  v-model="allItemsSelected"
                  :disabled="disableSelectAll"
                  :indeterminate="isIndeterminateState"
                ></v-checkbox-btn>
                <span class="font-semibold">
                  {{ i18n.t('header.titles.document_name') }}
                </span>
              </div>
              <div class="flex items-center">
                <div class="pr-[5.75rem]">
                  <button
                    class="items-center justify-center gap-1 hidden md:flex"
                    @click="() => (toggleOrder = !toggleOrder)"
                  >
                    <span class="font-semibold">
                      {{ i18n.t('header.titles.created_date') }}
                    </span>
                    <v-icon>{{
                      order === 'creationTime asc'
                        ? 'fa-solid fa-caret-up'
                        : 'fa-solid fa-caret-down'
                    }}</v-icon>
                  </button>
                </div>
                <div>
                  <span class="font-semibold">
                    {{ i18n.t('header.titles.status') }}
                  </span>
                </div>
              </div>
            </div>
            <!-- End of table header -->
            <v-list
              v-if="viewOption === 'list' && !newList"
              class="pt-2"
              color="primary"
              return-object
              slim
              lines="two"
              density="compact"
              data-test-id="document-list"
            >
              <v-list-item
                v-for="(item, index) in query.data.items"
                :key="item.documentId"
                class="px-0 select-none cursor-default"
                :class="[isSelected(item) ? 'v-list-item--active' : '']"
                :disabled="isDisabled(item)"
                :data-test-id="`document-list-item-${item.documentName}`"
                @click.stop="singleSelection(item, index, $event)"
                @dblclick="router.push(getCurrentStatusPageFromOverview(item))"
              >
                <template #prepend>
                  <v-list-item-action>
                    <v-checkbox-btn
                      :id="`selection-checkbox-${index}`"
                      v-model="selection"
                      :checked="isSelected(item)"
                      :value="item.documentId"
                      :data-test-id="`document-list-item-checkbox-${item.documentName}`"
                      @click.prevent
                      @dblclick.stop.prevent
                    ></v-checkbox-btn>
                  </v-list-item-action>
                </template>

                <v-list-item-title class="w-fit">
                  <PreviewTooltip :thumbnail-url="item.thumbnailUrl">
                    <template #activator>
                      <div
                        class="flex items-center gap-2 h-full whitespace-pre-wrap break-word flex-nowrap"
                      >
                        <router-link
                          class="pr-2 font-normal hover:md:underline min-w-0"
                          :to="getCurrentStatusPageFromOverview(item)"
                          @click.stop
                        >
                          {{ item.documentName }}
                        </router-link>
                      </div>
                    </template>
                  </PreviewTooltip>
                </v-list-item-title>
                <v-list-item-subtitle>
                  <div class="flex flex-wrap text-xs">
                    <div>
                      {{ item.initiatorCompanyDisplayName }}
                    </div>
                  </div>
                </v-list-item-subtitle>
                <template #append>
                  <div
                    class="flex justify-between items-center md:min-w-[300px]"
                  >
                    <div class="mr-4 hidden md:block">
                      {{ formatDateToLocale(item.creationTime, 'de-CH') }}
                    </div>
                    <VChip
                      :color="getStatusColor(item)"
                      class="flex mr-2 ml-auto"
                    >
                      {{ getStatusText(item) }}
                    </VChip>
                    <DocumentActionsMenu
                      :disabled="isSelected(item)"
                      :actions="getActions(item)"
                      :selection="selection"
                      :item="item"
                    ></DocumentActionsMenu>
                  </div>
                </template>
              </v-list-item>

              <InfiniteLoader
                v-if="query.hasNextPage && !query.isFetchingNextPage"
                @load-more="loadMore"
              />
            </v-list>
            <!-- <DocumentsListView v-else-if="newList" :query="query" /> -->

            <v-item-group
              v-if="viewOption === 'grid'"
              v-model="selection"
              selected-class="bg-primary"
              multiple
              class="py-2"
              data-test-id="document-list"
            >
              <v-row>
                <v-col
                  v-for="(item, index) in query.data.items"
                  :key="item.documentId"
                  cols="6"
                  sm="4"
                  md="3"
                  xl="2"
                  xxl="2"
                  class="flex flex-col"
                >
                  <v-item :value="item.documentId">
                    <template #default="{ isSelected: isChecked, select }">
                      <v-hover :disabled="device?.isMobile || device?.isTablet">
                        <template #default="{ isHovering, props }">
                          <v-card
                            class="h-full flex flex-col sticky top-0 border rounded transition-colors cursor-pointer"
                            :class="[
                              isChecked ? '!border-blue-500' : 'border-gray-200'
                            ]"
                            flat
                            :disabled="isDisabled(item)"
                            v-bind="props"
                            data-test-id="document-item-container"
                            @click.stop="
                              router.push(
                                getCurrentStatusPageFromOverview(item)
                              )
                            "
                            @dblclick="
                              router.push(
                                getCurrentStatusPageFromOverview(item)
                              )
                            "
                          >
                            <div
                              class="border overflow-hidden transition-colors"
                              data-test-id="document-item"
                              :class="[
                                isChecked ? '!bg-[#1A63FF14]' : 'bg-gray-200'
                              ]"
                            >
                              <v-img
                                aspect-ratio="1"
                                :src="item.thumbnailUrl"
                                :class="[
                                  'transition',
                                  isChecked ? 'scale-90' : ' scale-100'
                                ]"
                              ></v-img>
                              <div
                                v-if="isHovering || isChecked"
                                class="absolute top-0 left-0 p-1 m-1 bg-white rounded shadow-lg"
                              >
                                <v-checkbox-btn
                                  id="selection-checkbox-grid"
                                  class="selection-checkbox-grid"
                                  :model-value="isChecked"
                                  @update:model-value="select(!isChecked)"
                                  @click.stop="
                                    singleSelection(item, index, $event)
                                  "
                                  @dblclick.stop.prevent
                                ></v-checkbox-btn>
                              </div>
                              <div
                                class="2xl:hidden absolute top-0 left-0 p-1 m-1 bg-white rounded shadow-lg"
                              >
                                <v-checkbox-btn
                                  id="selection-checkbox-grid"
                                  class="selection-checkbox-grid"
                                  :model-value="isChecked"
                                  @update:model-value="select(!isChecked)"
                                  @click.stop="
                                    singleSelection(item, index, $event)
                                  "
                                  @dblclick.stop.prevent
                                ></v-checkbox-btn>
                              </div>
                            </div>

                            <v-card-item class="card-title">
                              <v-card-title
                                class="flex items-center justify-between my-1"
                              >
                                <RouterLink
                                  class="hover:underline truncate cursor-pointer"
                                  :to="getCurrentStatusPageFromOverview(item)"
                                  @click.stop
                                >
                                  {{ item.documentName }}
                                </RouterLink>
                                <DocumentActionsMenu
                                  :selection="selection"
                                  :disabled="isChecked"
                                  :actions="getActions(item)"
                                  :item="item"
                                />
                              </v-card-title>
                            </v-card-item>
                            <v-card-actions class="py-0 px-3 mt-auto">
                              <div
                                class="flex items-center justify-between w-full"
                              >
                                <VChip
                                  size="small"
                                  :color="getStatusColor(item)"
                                >
                                  {{ getStatusText(item) }}
                                </VChip>
                              </div>
                            </v-card-actions>
                          </v-card>
                        </template>
                      </v-hover>
                    </template>
                  </v-item>
                </v-col>
                <InfiniteLoader
                  v-if="query.hasNextPage && !query.isFetchingNextPage"
                  @load-more="loadMore"
                />
              </v-row>
            </v-item-group>
          </template>
          <template v-else>
            <v-sheet>
              <v-empty-state
                :text="
                  q
                    ? i18n.t('text.no_result_description')
                    : i18n.t('text.no_documents_description')
                "
                :title="
                  q ? i18n.t('text.no_results') : i18n.t('text.no_document')
                "
                bg-color="#F4F4F5"
                data-test-id="dashboard-empty-state-container"
              >
                <template #actions>
                  <VBtn
                    v-if="q"
                    data-test-id="empty-state-clear-search-button"
                    prepend-icon="fa-regular fa-magnifying-glass-minus"
                    color="primary"
                    @click="handleClearSearch"
                  >
                    {{ i18n.t('buttons.clear_search') }}
                  </VBtn>
                  <UploadNewDocumentButton v-else />
                </template>
              </v-empty-state>
            </v-sheet>
          </template>
        </template>
      </template>
    </div>
  </v-container>

  <DialogSessionInvalid />
  <DeleteDocumentsDialog @done="handleRefetching" />
  <!--  <DeepLoader />-->
  <DocumentWithdrawDialog @done="handleRefetching" />
  <CreateDocumentDialog
    v-if="AuthService.isAuthenticated && !isDocumentAuthAuthenticated"
    @done="handleRefetching"
  />
  <UploadDocumentValidation v-if="AuthService.isAuthenticated" />
  <BatchProcessDialog @handle-signed-documents="handleSignedDocuments" />
  <BatchSimpleProcessDialog @handle-signed-documents="handleSignedDocuments" />
</template>

<script setup lang="ts">
import { useInfiniteQuery } from '@tanstack/vue-query';
import globalAxios from '@/composables/useAxios';
import { getStatusColor, getStatusText } from '@/services/documentService';
import AuthService from '@/services/auth-service.ts';
import { useDocumentAuth } from '@/composables/useDocumentAuth.ts';
import {
  computed,
  inject,
  onBeforeMount,
  onUnmounted,
  provide,
  reactive,
  ref,
  toRef,
  watch,
  watchEffect
} from 'vue';
import { usePreferencesStore } from '@/stores/deepadmin/preferences.ts';
import { useRouteQuery } from '@vueuse/router';
import DocumentActionsMenu from '@/components/dashboard/new/DocumentsListView/DocumentActionsMenu.vue';
import { useDocumentActions } from '@/composables/useDocumentActions.ts';
import { getCurrentStatusPageFromOverview } from '@/services/documentService.ts';
import { formatDateToLocale } from '@/composables/useUtils.ts';
import type { OverviewDocument } from '@/stores/overview/overview.ts';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useStorage } from '@vueuse/core';
import { useEmitter } from '@/composables/useEmitter';
import { DocumentStatus } from '@/types/enums/DocumentStatus';
import { SignStatus } from '@/types/enums/SignStatus';
import { DeviceKey } from '@/plugins/device-detector-js';
import { VCheckbox } from 'vuetify/components';
import { nextTick } from 'process';

const documentAuth = useDocumentAuth();
const preferencesStore = usePreferencesStore();
const selection = ref<string[]>([]);
const i18n = useI18n();
const emitter = useEmitter();
const newList = ref(false);
const device = inject(DeviceKey);
const route = useRoute();
const router = useRouter();

const toggleOrder = ref(false);
const allItemsSelected = ref(false);
const selectAllCheckbox = ref<InstanceType<typeof VCheckbox>>();
const offset = ref(0);
const filterSignStatusSession = useStorage(
  'filterSignStatusSession',
  undefined,
  sessionStorage
);
const documentSignStatusSession = useStorage(
  'documentSignStatusSession',
  undefined,
  sessionStorage
);
const limit = useRouteQuery('limit', '50', { transform: Number });
const order = useRouteQuery<'creationTime desc' | 'creationTime asc'>(
  'order',
  'creationTime desc'
);
const filterSignStatus = useRouteQuery<string>('filterSignStatus');
const filterDocumentStatus = useRouteQuery<string>('filterDocumentStatus');
const signatureType = useRouteQuery<string[]>('signatureType');
const q = useRouteQuery('q', undefined);

const viewOption = ref<'grid' | 'list'>();

emitter.$on('change-view-preference', handleChangeViewPreference);

const isDocumentAuthAuthenticated = computed(() => {
  return documentAuth.isAuthenticated.value;
});

const filterSignStatusComputed = computed(() => {
  if (Array.isArray(filterSignStatus.value)) return filterSignStatus.value[0];
  return filterSignStatus.value;
});

const filterDocumentStatusComputed = computed(() => {
  if (Array.isArray(filterDocumentStatus.value))
    return filterDocumentStatus.value[0];
  return filterDocumentStatus.value;
});

const onToSignRoute = computed(() => {
  return (
    filterSignStatusComputed.value === SignStatus.PENDING &&
    filterDocumentStatusComputed.value === DocumentStatus.IN_PROGRESS
  );
});

const disableSelectAll = computed(
  () => onToSignRoute.value && selection.value.length < 1
);

const isIndeterminateState = computed(
  () =>
    query.data?.items &&
    selection.value.length >= 1 &&
    query.data.items.length !== selection.value.length
);

function handleChangeViewPreference(newValue) {
  viewOption.value = newValue;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isSelected = (option: any) => selection.value.includes(option.documentId);
const selectedDocuments = computed(() => {
  return selection.value.map((id: string) => {
    return query.data?.items?.find((doc) => {
      return doc.documentId === id;
    });
  });
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isDisabled = (option: any) => {
  if (!selection.value[0]) return false;
  const firstID = selection.value[0];
  if (firstID === option.documentId) return false;
  const firstDocument = selectedDocuments.value.find(
    (doc) => doc?.documentId === firstID
  );
  if (!firstDocument) return false;
  return (
    onToSignRoute.value &&
    (firstDocument.documentStatus !== option.documentStatus ||
      firstDocument.signatureType !== option.signatureType ||
      firstDocument.signStatus !== option.signStatus ||
      firstDocument.jurisdiction !== option.jurisdiction ||
      firstDocument.signatureMode !== option.signatureMode)
  );
};

provide('selectedDocuments', selectedDocuments);

function getActions(document: OverviewDocument) {
  const { actions } = useDocumentActions(
    document.documentStatus,
    document.signStatus,
    toRef([document])
  );
  return actions.value;
}

function handleSignedDocuments() {
  selection.value = [];
  allItemsSelected.value = false;
  offset.value = 0;
  query.refetch();
}

const lastKnownSelectionIndex = ref<number>(-1);

function addToModel(item: OverviewDocument) {
  if (!selection.value.includes(item.documentId)) {
    selection.value.push(item.documentId);
  }
}

function selectRange(fromIndex: number, toIndex: number): void {
  const [first, second] = [fromIndex, toIndex].sort((a, b) => a - b);
  selection.value = [];
  for (let i = first; i <= second; i++) {
    const item = query?.data?.items?.[i];
    const idToAdd = item?.documentId;
    if (!isDisabled(item) && idToAdd) {
      addToModel(item);
    }
  }
}

function addOrRemove(item) {
  if (selection.value.includes(item.documentId)) {
    selection.value = selection.value.filter((id) => id !== item.documentId);
  } else {
    selection.value.push(item.documentId);
  }
}

async function singleSelection(
  item: OverviewDocument,
  selectedItemIndex: number,
  event
) {
  if (event.shiftKey) {
    selectRange(lastKnownSelectionIndex.value, selectedItemIndex);
  } else if (event.metaKey || event.ctrlKey) {
    lastKnownSelectionIndex.value = selectedItemIndex;
    addOrRemove(item);
  } else {
    lastKnownSelectionIndex.value = selectedItemIndex;
    if (event.target.tagName && event.target.tagName !== 'INPUT') {
      selection.value = [];
    }
    addOrRemove(item);
  }
}

type Pagination = {
  offset: number;
  limit: number;
};

type DocumentResponse = {
  documents: OverviewDocument[];
  pagination: Pagination;
  size: number;
};

const query = reactive(
  useInfiniteQuery({
    queryKey: [
      'documents',
      {
        limit,
        filterSignStatus,
        filterDocumentStatus,
        signatureType,
        q,
        order
      }
    ],
    queryFn: ({ signal, pageParam = 0, queryKey }) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, params] = queryKey;
      return globalAxios
        .get<DocumentResponse>(
          `${import.meta.env.VITE_DEEPSIGN_API_BASE_URL}overview`,
          {
            params: {
              ...(typeof params === 'object' ? params : {}),
              offset: pageParam // Offset is handled by pageParam
            },
            signal // Attach the abort signal to the request
          }
        )
        .then((response) => response.data);
    },
    select: (data) => {
      const items = data.pages.flatMap((page) => page.documents);
      return {
        items: items,
        total: items.length
      };
    },
    staleTime: 5 * 1000,
    getNextPageParam: (lastPage) => {
      const { pagination, size } = lastPage;
      const { offset, limit } = pagination;

      // Calculate the next offset
      const nextOffset = offset + limit;

      // Check if the next offset exceeds the total size
      if (nextOffset >= size) {
        return undefined; // No more pages to fetch
      }

      return nextOffset; // Return th
    },
    initialPageParam: offset.value,
    refetchOnWindowFocus: false
  })
);

function handleRefetching() {
  selection.value = [];
  allItemsSelected.value = false;
  query.refetch();
}

function loadMore() {
  query.fetchNextPage();
}

function handleClearSearch() {
  emitter.$emit('focus-search-bar');
  q.value = undefined;
}

onBeforeMount(async () => {
  await preferencesStore.fetchAll();
  order.value =
    (preferencesStore!.preferences?.createdDateOrder as
      | 'creationTime desc'
      | 'creationTime asc') || 'creationTime desc';
});

onUnmounted(() => {
  emitter.$off('change-view-preference', handleChangeViewPreference);
});

watchEffect(() => {
  filterSignStatusSession.value = filterSignStatus.value;
  documentSignStatusSession.value = filterDocumentStatus.value;
});

watch(
  () => route.fullPath,
  () => {
    selection.value = [];
    allItemsSelected.value = false;
  }
);

watch(
  () => toggleOrder.value,
  (newValue) => {
    allItemsSelected.value = false;
    selection.value = [];
    if (newValue) {
      preferencesStore.setPreference('createdDateOrder', 'creationTime asc');
      order.value = 'creationTime asc';
    } else {
      preferencesStore.setPreference('createdDateOrder', 'creationTime desc');
      order.value = 'creationTime desc';
    }
  }
);

watchEffect(() => {
  if (
    selection.value.length > 0 &&
    selection.value.length === query.data?.items.length
  ) {
    allItemsSelected.value = true;
  }
  if (selection.value.length === 0) {
    allItemsSelected.value = false;
  }
});

watch(
  () => allItemsSelected.value,
  (newValue) => {
    if (newValue) {
      if (isIndeterminateState.value) {
        selection.value = [];
        nextTick(() => {
          allItemsSelected.value = false;
        });
      } else {
        const selectedIds = new Set(selection.value);
        query.data?.items.forEach((item) => {
          if (!selectedIds.has(item.documentId) && !isDisabled(item)) {
            selection.value.push(item.documentId);
          }
        });
      }
    } else {
      selection.value = [];
    }
  }
);
</script>

<style scoped lang="postcss">
.fade-enter-active,
.fade-leave-active {
  transition: all 0.25s ease-out;
}

.fade-enter-from {
  opacity: 0;
  transform: translateX(0px);
}

.fade-leave-to {
  opacity: 0;
  transform: translateX(0px);
}

:deep(.v-list-item + .v-list-item) {
  @apply -mt-[1px];
}
:deep(.v-list-item--active) {
  @apply border-b-2;
  background-color: rgba(0, 82, 255, 10%);
}

:deep(.v-list-item--active:hover) {
  background-color: rgba(0, 82, 255, 10%);
}

:deep(.v-list-item) {
  @apply border-b-2 border-b-gray-100;
}

:deep(.v-card-title) {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.25;
}

:deep(.v-selection-control__input::before) {
  background-color: transparent !important;
}

:deep(
    .v-list-item--active > .v-list-item__overlay,
    .v-list-item[aria-haspopup='menu'][aria-expanded='true']
      > .v-list-item__overlay
  ) {
  background-color: transparent !important;
}

:deep(.selection-checkbox-grid.v-selection-control--density-default) {
  --v-selection-control-size: 25px !important;
}

:deep(.v-card-item.card-title) {
  padding-left: 1rem !important;
  padding-right: 0rem !important;
  padding-bottom: 0rem !important;
  padding-top: 0rem !important;
  margin: 0% !important;
}

:deep(.v-card-actions) {
  min-height: 24px !important;
  padding-bottom: 0.5rem !important;
}

@media (min-width: 1367px) {
  .\32xl\:hidden {
    display: none !important;
  }
}
</style>
