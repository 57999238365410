<template>
  <div
    class="flex items-center justify-between bg-white rounded-b-lg"
    data-test-id="documents-count"
  >
    <h5>
      {{ i18n.t('text.documents_total', { n: total }) }}
    </h5>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';

const i18n = useI18n();

defineProps({
  total: {
    type: Number,
    required: true
  }
});
</script>
