import { DocumentStatus } from '@/types/enums/DocumentStatus';
import { SignStatus } from '@/types/enums/SignStatus';
import { computed, type ComputedRef } from 'vue';
import { useEmitter } from './useEmitter';
import { downloadDocumentPdf } from '@/services/documentService.ts';
import { useRouter } from 'vue-router';
import { SignatureMode } from '@/types/enums/SignatureMode';
import { SignatureType } from '@/types/enums/SignatureType';
import i18n from '@/plugins/i18n';
import type { OverviewDocument } from '@/stores/overview/overview';

export function useDocumentActions(
  documentStatus: DocumentStatus,
  signStatus: SignStatus,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  documents: ComputedRef<OverviewDocument[]>
) {
  const emitter = useEmitter();
  const router = useRouter();

  // Define commonly used actions as constants
  const downloadAction = {
    label: i18n.global.t('buttons.download'),
    icon: 'fa-regular fa-download',
    type: 'default',
    action: async () => {
      if (documents.value && documents.value.length > 0) {
        for (const document of documents.value) {
          await downloadDocumentPdf(
            document?.signKey,
            document?.documentName,
            document?.documentStatus,
            document?.documentId
          );
        }
      }
    }
  };

  const deleteAction = {
    label: i18n.global.t('buttons.delete'),
    icon: 'fa-regular fa-trash',
    type: 'warning',
    action: () => {
      emitter.$emit('open-delete-documents-dialog', {
        documents: documents.value
      });
    }
  };

  const withdrawAction = {
    label: i18n.global.t('buttons.withdraw'),
    icon: 'fa-regular fa-file-excel',
    type: 'default',
    action: () => {
      emitter.$emit('open-document-withdraw-dialog', documents.value);
    }
  };

  const signAction = {
    label:
      documents.value[0].signatureType === SignatureType.SIGNATURE
        ? i18n.global.t('buttons.sign')
        : i18n.global.t('buttons.seal'),
    icon:
      documents.value[0].signatureType === SignatureType.SIGNATURE
        ? 'fa-regular fa-pen-nib'
        : 'fa-regular fa-stamp',
    type: 'default',
    action: () =>
      router.push({
        name: 'document-sign',
        params: { document: documents.value[0].documentId }
      })
  };

  const batchSignAction = {
    label: i18n.global.t('buttons.sign'),
    icon: 'fa-regular fa-pen-nib',
    type: 'default',
    action: () => {
      if (documents.value[0].signatureMode === SignatureMode.TIMESTAMP) {
        emitter.$emit('open-simple-batch-process-dialog', documents);
      } else {
        emitter.$emit('open-batch-process-dialog', documents);
      }
    }
  };

  const actions = computed(() => {
    const hasOneDocument =
      Array.isArray(documents.value) && documents.value.length === 1;
    const canDeleteDocuments = documents.value.every(
      (document) => document.policy.canDelete
    );
    const canWithdrawDocument = documents.value.every((document) => {
      return document.policy.canWithdraw;
    });

    const canSign = documents.value.every((document) => {
      return document.policy.canSign;
    });

    const areSignature = documents.value.every((document) => {
      return document.signatureType === SignatureType.SIGNATURE;
    });

    const isMissingSignature = documents.value.every(
      (document) => document.signStatus === SignStatus.PENDING
    );

    // "To sign" tab
    if (
      documentStatus === DocumentStatus.IN_PROGRESS &&
      signStatus === SignStatus.PENDING
    ) {
      if (hasOneDocument) {
        return [
          ...(canSign ? [signAction] : []),
          ...(canWithdrawDocument ? [withdrawAction] : []),
          downloadAction
        ];
      } else {
        return [
          ...(areSignature && canSign ? [batchSignAction] : []),
          downloadAction
        ];
      }
    }

    // "Draft" tab
    if (documentStatus === DocumentStatus.DRAFT) {
      return [deleteAction, downloadAction];
    }

    // "Pending" tab
    if (documentStatus === DocumentStatus.IN_PROGRESS) {
      if (hasOneDocument) {
        return [
          downloadAction,
          ...(canWithdrawDocument ? [withdrawAction] : []),
          ...(isMissingSignature && canSign ? [signAction] : [])
        ];
      } else {
        return [downloadAction];
      }
    }

    // "Completed" tab
    if (
      [
        DocumentStatus.REJECTED,
        DocumentStatus.WITHDRAWN,
        DocumentStatus.SIGNED
      ].includes(documentStatus)
    ) {
      return [downloadAction, deleteAction];
    }

    // "All" tab
    if (hasOneDocument) {
      return [
        downloadAction,
        ...(canWithdrawDocument ? [withdrawAction] : []),
        ...(canSign && isMissingSignature ? [signAction] : []),
        ...(canDeleteDocuments ? [deleteAction] : [])
      ];
    } else {
      return [downloadAction];
    }
  });

  return { actions };
}
