<template>
  <div>
    <label for="singature-upload-file">
      {{ i18n.t('components.signaturePad.signature') }}
    </label>
    <div class="relative">
      <div
        ref="dropZoneRef"
        :class="{ 'is-over-drop-zone': isOverDropZone && !initialSignatureUrl }"
        class="bg-white grid-12 border border-[#9E9E9E] rounded select-none"
      >
        <div v-if="isOverDropZone && !initialSignatureUrl" class="overlay">
          {{ i18n.t('components.signaturePad.drop_image_here') }}
        </div>

        <input
          id="singature-upload-file"
          ref="fileInput"
          accept="image/png, image/jpeg"
          class="hidden"
          type="file"
          @input="upload"
        />
        <div class="lg:aspect-ratio">
          <VueSignaturePad
            v-if="!initialSignatureUrl || drawing"
            id="settings-signature-pad"
            ref="settingsSignaturePad"
            class="pad"
            width="100%"
            :options="{ onEnd, penColor: 'rgba(22, 32, 81, 0.9)' }"
          />
          <img
            v-else
            id="uploadPreview"
            :src="initialSignatureUrl"
            class="uploadPreview"
            alt="signature image preview"
          />
        </div>
        <hr class="border-[#9E9E9E] mx-4" />

        <div class="flex items-center justify-center mx-4 my-2">
          <span v-if="!initialSignatureUrl" class="input-label text-gray-400">
            <I18nT keypath="components.signaturePad.draw_upload_signature">
              <template #upload>
                <button
                  type="button"
                  class="text-blue-600 underline"
                  @click="fileInput ? fileInput.click() : undefined"
                >
                  {{ i18n.t('components.signaturePad.upload') }}
                </button>
              </template>
            </I18nT>
          </span>

          <button
            v-if="initialSignatureUrl"
            type="button"
            class="text-gray-400"
            @click="clearSignature"
          >
            {{ i18n.t('components.signaturePad.remove') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watchEffect } from 'vue';
import { useDropZone } from '@vueuse/core';
import { useI18n } from 'vue-i18n';

const props = defineProps({
  initialSignatureUrl: {
    type: String,
    default: undefined
  }
});

const emit = defineEmits(['updateSignatureUrl']);
const i18n = useI18n();
const settingsSignaturePad = ref();
const drawing = ref(false);
const fileInput = ref<HTMLInputElement>();
const dropZoneRef = ref<HTMLDivElement>();
const { isOverDropZone } = useDropZone(dropZoneRef, onDrop);
const signatureUrl = ref(props.initialSignatureUrl || '');

watchEffect(() => {
  if (props.initialSignatureUrl) {
    signatureUrl.value = props.initialSignatureUrl;
  }
});

function onDrop(files: File[] | null) {
  if (
    !signatureUrl.value &&
    files &&
    files[0] &&
    files[0].type.startsWith('image/')
  ) {
    const newUrl = URL.createObjectURL(files[0]);
    emitUpdate(newUrl);
  }
}

function upload(event: Event) {
  const input = event.target as HTMLInputElement;
  const files = input.files;
  if (files && files.length > 0) {
    const newUrl = URL.createObjectURL(files[0]);
    drawing.value = false;
    emitUpdate(newUrl);
  }
}

function onEnd() {
  drawing.value = true;
  const { data } = settingsSignaturePad.value.saveSignature();
  emitUpdate(data);
}

function emitUpdate(url: string) {
  signatureUrl.value = url;
  emit('updateSignatureUrl', url);
}

function clearSignature() {
  if (fileInput.value) {
    fileInput.value.value = '';
  }
  if (settingsSignaturePad.value) {
    settingsSignaturePad.value.clearSignature();
  }
  signatureUrl.value = '';

  emit('updateSignatureUrl', '');
}

resizePad();

function resizePad() {
  setTimeout(() => {
    const signaturePadElement = document.getElementById(
      'settings-signature-pad'
    );
    if (
      settingsSignaturePad.value?.signaturePad.canvas.height === 0 &&
      signaturePadElement
    ) {
      settingsSignaturePad.value.signaturePad.canvas.height =
        signaturePadElement.clientHeight;
      settingsSignaturePad.value.signaturePad.canvas.width =
        signaturePadElement.clientWidth;
      settingsSignaturePad.value.signaturePad.canvas.style.height = `${signaturePadElement.clientHeight}px`;
    }
  }, 300);
}
</script>

<style lang="postcss">
.is-over-drop-zone {
  @apply bg-gray-300;
}
.aspect-ratio {
  aspect-ratio: 196/48;
}

.overlay {
  @apply absolute top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 text-white;
  font-size: 1.5rem;
}

.uploadPreview {
  max-height: 140px;
  width: 100%;
  object-fit: contain !important;
  aspect-ratio: 196/48;
}

.pad {
  @apply aspect-ratio;
  cursor: url('data:image/svg+xml;utf8,<svg width="18" height="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="rgba(22,32,81,0.9)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M12 19l7-7 3 3-7 7-3-3z"/><path d="M18 13l-1.5-7.5L2 2l3.5 14.5L13 18l5-5z"/><path d="M2 2l7.586 7.586"/><circle cx="11" cy="11" r="2"/></svg>'),
    crosshair;
}
</style>
