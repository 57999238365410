<template>
  <v-dialog v-model="showDialog" persistent width="600">
    <v-card class="pa-6 scrollbar-menu-card !rounded-[12px]">
      <!-- SMS CODE -->
      <SmsCodeIdentifyCard
        v-if="isSmsCode"
        @done="handleIdentificationSuccess"
        @cancel="handleIdentificationCancel"
        @show-warning="handleIdentificationError"
      />
      <!-- /SMS CODE -->

      <!-- DEEP ID -->
      <DeepIDIdentifyCard
        v-if="!deepAdminInternalStore.isVerified && isDeepID"
        @close-dialog="closeDialog"
      />
      <!-- /DEEP ID -->

      <!-- MOBILE ID -->
      <MobileIDIdentifyCard
        v-if="isMobileID"
        @done="handleIdentificationSuccess"
        @cancel="handleIdentificationCancel"
        @show-mobile-id-warning="handleIdentificationError"
      />
      <!-- /MOBILE ID -->

      <!-- VERIFIED -->
      <SuccessfulVerifiedCard
        v-if="deepAdminInternalStore.isVerified && isDeepID"
        :authority-service="authService"
        @close-dialog="closeDialog"
      />
      <!-- /VERIFIED -->
    </v-card>
  </v-dialog>
</template>

<script lang="ts" setup>
import AuthService from '@/services/auth-service';
import { useMessageStore } from '@/stores/message/message.ts';
import { SignatureMode } from '@/types/enums/SignatureMode.ts';
import { AuthorityService } from '@/types/enums/AuthorityService.ts';
import { useDeepAdminInternalStore } from '@/stores/deepadmin/deepadmin-internal.ts';
import { ref, computed, onUnmounted } from 'vue';
import { useEmitter } from '@/composables/useEmitter';

const messageStore = useMessageStore();
const deepAdminInternalStore = useDeepAdminInternalStore();
const emitter = useEmitter();
const showDialog = ref(false);
const onboardingTimeout = ref();
const authService = ref(null);
const signatureMode = ref(null);
const emit = defineEmits([
  'identification-finished',
  'identification-failed',
  'identification-canceled'
]);
emitter.$on('restart-verification', restartVerification);
emitter.$on('open-batch-identify-dialog', openDialog);

onUnmounted(() => {
  emitter.$off('open-batch-identify-dialog', openDialog);
  emitter.$off('restart-verification', restartVerification);
});

const isSmsCode = computed(() => {
  return (
    authService.value === AuthorityService.MOBILE_ID &&
    signatureMode.value === SignatureMode.ADVANCED
  );
});

const isDeepID = computed(() => {
  return authService.value === AuthorityService.DEEP_ID;
});

const isMobileID = computed(() => {
  return (
    authService.value === AuthorityService.MOBILE_ID &&
    signatureMode.value === SignatureMode.QUALIFIED
  );
});

function openDialog({ signMode, authServiceKey }) {
  authService.value = authServiceKey;
  signatureMode.value = signMode;
  if (authService.value === AuthorityService.DEEP_ID) {
    checkStatus();
  }
  showDialog.value = true;
}

function handleIdentificationError() {
  emit('identification-failed', isSmsCode.value ? 'sms-code' : 'mobile-id');
  closeDialog();
}

function handleIdentificationSuccess() {
  emit('identification-finished');
  closeDialog();
}

function handleIdentificationCancel() {
  emit('identification-canceled');
  closeDialog();
}

function closeDialog() {
  showDialog.value = false;
  authService.value = null;
  clearTimeout(onboardingTimeout.value);
}

async function checkStatus() {
  try {
    await deepAdminInternalStore.fetchOnboardingStatus();
    if (
      deepAdminInternalStore.isVerified &&
      deepAdminInternalStore.isDeepIDVerification
    ) {
      emitter.$emit('enable-deep-id');
    }
    if (deepAdminInternalStore.shouldStartValidation) {
      const payload = {
        first_name: AuthService.user?.given_name,
        last_name: AuthService.user?.family_name,
        source: 'deepid',
        purpose: 'deepsign'
      };
      await deepAdminInternalStore.startUserValidation(payload).then(() => {
        checkStatus();
      });
    } else if (deepAdminInternalStore.isInProgress) {
      onboardingTimeout.value = setTimeout(checkStatus, 3000);
    } else {
      clearTimeout(onboardingTimeout.value);
    }
  } catch (error) {
    messageStore.showMessage({
      key: 'errors.custom.identification_status',
      color: 'error',
      icon: 'fa fa-circle-exclamation'
    });
  }
}

async function restartVerification() {
  clearTimeout(onboardingTimeout.value);
  const payload = {
    first_name: AuthService.user?.given_name,
    last_name: AuthService.user?.family_name,
    source: 'deepid',
    purpose: 'deepsign'
  };
  await deepAdminInternalStore.startUserValidation(payload).finally(() => {
    checkStatus();
  });
}
</script>
